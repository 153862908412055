import { Component, OnInit } from '@angular/core';
import { currency, isBrower } from '../../config/config'
import { CommonserviceService } from '../../services/commonservice.service';
import {  ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-cash',
  templateUrl: './add-cash.page.html',
  styleUrls: ['./add-cash.page.scss'],
})
export class AddCashPage implements OnInit {

  currency = currency;
  showPaydirect:number=1;
  wallet_amount:any= 25;
  showCloseBtn = true;
  selectedCashCard=1;
  discount_label = 25;
  vaild_wallet = true;
  payment_url='';
  cashError:string;
  nameUser:string;  site_name:any;  orderId:any;  userId:any;  url:any; amount:number; email:string; phone:any;
  wallet_total:number=0;
  credit_total: number=0;
  final_total: number=0;
  status:any=''; 
  constructor( public commonService: CommonserviceService,public modalCtrl : ModalController) { 
    this.status=localStorage.getItem('addcashFromContest');
   }

  ngOnInit() {
    this.callToVerify();
    this.paymentUrl();
  }

  inputWallet() {
    if (this.wallet_amount == null) {
      this.showCloseBtn = false;
    }
    else {
      this.showCloseBtn = true;
    }
    if (this.wallet_amount < 25) {
      this.vaild_wallet = false
      this.cashError = 'The minimum amount is INR 25';
    }else if(isNaN(this.wallet_amount)){
      this.vaild_wallet = false
      this.cashError = 'Enter valid amount';
    }else if(this.wallet_amount % 1 != 0){
      this.vaild_wallet = false
      this.cashError = 'Amount should be whole number';
    }else if(this.wallet_amount > 500000){
      this.vaild_wallet = false
      this.cashError = 'The maximum amount is INR 500000';
    }
    else {
      this.vaild_wallet = true
    }
  }
  clearInputValue() {
    this.wallet_amount = null;
    this.selectedCashCard = 0;
  }
  chooseCard(val,amount,discount)
  {
      this.selectedCashCard=val;
      this.wallet_amount=amount;
      this.discount_label = discount;
  }

  close()
  {
    this.modalCtrl.dismiss();
  }

  paymentRedirect(){
    // this.loading.presentLoading();
    if(this.vaild_wallet)
    {
    var timeNow = new Date().getTime();
    this.site_name = localStorage.getItem("token");
    var userinfo = JSON.parse(localStorage.getItem('userData')) ;
    this.nameUser = userinfo.name;
    this.orderId = 'INV_' + timeNow
    this.userId = userinfo.id;
    this.phone = userinfo.mobile_number;
    this.email = userinfo.email;
    this.showPaydirect=0;
    console.log(this.wallet_amount + "&email=" + this.email + "&phone=" + this.phone + "&orderId=" + this.orderId + "&productInfo=deposit" + "&userId=" + this.userId + "&name=" + this.nameUser);
    this.url = this.payment_url+"?amount=" + this.wallet_amount + "&email=" + this.email + "&phone=" + this.phone + "&orderId=" + this.orderId + "&productInfo=deposit" + "&userId=" + this.userId + "&name=" + this.nameUser+ "&browser="+ isBrower;
    console.log(this.url)
    window.open(this.url,'_system');
    }
  }

  makeid(length:number) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
   return result;
  }

  callToVerify() {
    let postData = {

    }
    this.commonService.commonApiService('get','balance','').then((getDetails:any)=>{
      if(getDetails.status == 200) {
        console.log("success",getDetails.message);
        // this.commonService.toastPreview(getDetails.message);
        
        this.wallet_total = JSON.parse(getDetails.data.wallet);
        this.credit_total = JSON.parse(getDetails.data.credit);
        this.final_total = this.wallet_total + this.credit_total;
        console.log("final", this.final_total)
      } else {
        this.commonService.toastPreview(getDetails.message);
        console.log("error", getDetails.message);
        
      }
    })
  }

  paymentUrl(){
    let postData = { "keys" : ["payment_url"] }
    this.commonService.commonApiService('post','getSettingsContent',postData).then((getPaymentUrl:any)=>{
      console.log(getPaymentUrl.content[0].content)
      if(getPaymentUrl.status == 200) {
       this.payment_url = getPaymentUrl.content[0].content;
      }
    })
  }
}
